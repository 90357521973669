<div class="body-content">
    <div class="container-fluid">

        <div  class="porfolio-header">
            <h3><i class="zmdi zmdi-city"></i> <span class="portfolio-main-title">
                My Portfolio
            </span> :
                 <span class="portfolio-building-title">
                     All Buildings
                 </span></h3>

            <label class="period-info">
                Today's Date : 
                <span>
                    {{newDate}}
                    
                </span></label>

            <div class="data-settings" data-toggle="modal" data-target="#dataSettings">
                <a data-toggle="tooltip" data-placement="left" title="Portfolio Settings"><i class="fa fa-gear"></i></a>
            </div>
        </div>

        <div  class="filterable-section">

            <div class="row">
                <div class="col-md-3">
                   
                </div>

                <div class="col-md-6">
                    <div class="three-section-height" id="googleMap">
                        <div class="google-map">
                           
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                     <div>
                     </div>
                     <div>
                     </div>
                     
                    
                </div>
            </div>

        </div>
        
 
    </div>
</div>
