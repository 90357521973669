<footer>
  <div class="info">
    <div>Terms of service</div>
    <div><a href="https://www.cbre.com/about/privacy-policy" target="_blank">Privacy Policy</a></div>
    <div>Supported browsers</div>
  </div>
  <div class="company info">
    <div>&copy; 2024 CBRE, Inc.</div>
    <div>App Version: {{version}}</div>
  </div>

</footer>
