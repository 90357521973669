<div class="modal_custom" style="padding: 0; min-width: 600px; ">
  <div class="header-section modal-header" style="position: relative; height: 50px">
    <h2 mat-dialog-title class="diolog-title-tronform" style="bottom: 50px;    margin-bottom: 0; margin-left: -6%;">
      <div style="margin-left: 30px; " [innerHTML]="data.title"></div>
      <button
        style="position:absolute; box-shadow: none; right: 1px; top:41px; color: red;"
        class="mat-raised-button popup-close btn btn-modal-close pull-right"
        mat-dialog-close
      >
        x
      </button>
    </h2>
  </div>
</div>
<div mat-dialog-content >
  <div class="container-fluid" >
    <div class="row mt-16">
      <div  [innerHTML]="data.description"></div>
      <div >{{ timeRemainingMessage }}</div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <div class="col-md-12 button-section">
    <div class="row">
      <!-- [mat-dialog-close]="data.dialogData" -->

      <button
        style="min-width: 80px"
        class="btn btn-primary"
        cdkFocusInitial
        (click)="onOkClick()"
      >
        {{ yesButtonText }}
      </button>
      &nbsp;&nbsp;
      <button mat-button type="button" class="btn btn-primary button-cancel"
      style=" border-radius: 8px;height: 40px;" (click)="onNoClick()">
        {{ noButtonText }}
      </button>
    </div>
  </div>
</div>
