<div class="body-content" id="">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="title-row">
          <h3>Welcome to {{ appSettingsService.appName }}</h3>
        </div>
      </div>
    </div>
    <div class="row mt-48" *ngIf="!authService.CurrentUser">
      <div class="col-md-12">
        <h4 style="color: dimgray;text-align: justify;">
          {{ authorizationStatusMessage }}
        </h4>
      </div>
    </div>
  </div>
</div>