import { VERSION } from "./version";

export const environment = {
  production: false,
  envName: "QA",
  apiUrl: 'https://gia-api-qa.cbre.com',
  tenant: "cbrelabsEXT.onmicrosoft.com",
  instance: "https://cbrelabsEXT.b2clogin.com/",
  clientId: "2fecdbbf-adad-4ed0-b105-9ae93a525a42",
  authnPolicy: "b2c_1a_giadataportal__signup_signin",
  pwdResetPolicy: "B2C_1A_GIADATAPORTAL_PASSWORDRESET",
  appVersion: VERSION.version,
  minutesUntilAutoLogout: 240
};
