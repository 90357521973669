<h1 style="text-transform: none" mat-dialog-title [innerHTML]="data.title" style="margin-left: 15px"></h1>
<div mat-dialog-content>
  <!-- <div style="margin-left: 15px;" [innerHTML]="data.description"></div> -->
  <div style="margin-left: 15px">{{ data.description.substring(0, 144) }}</div>
  <div >
    {{ data.description.substring(144, data.description.length) }}
  </div>
</div>
<div mat-dialog-actions align="end">
  <!-- <button style="margin-right: 25px;" class="mat-raised-button mat-primary" [mat-dialog-close]="data.dialogData"
        cdkFocusInitial>Ok</button> -->
  <button
    mat-button
    style="margin-right: 25px;color:white"
    class="mat-button btn-primary button-ok"
    [mat-dialog-close]="data.dialogData"
  >
    OK
  </button>
</div>
<!-- <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions> -->
