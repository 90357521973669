<!-- <div class="app-grid">
  <app-left-nav></app-left-nav>
  <div >
    <app-header></app-header>
    <div>
      <router-outlet></router-outlet>
    </div>

  </div>
  <app-footer></app-footer>
</div> -->

<div class="app-grid">

  <div>
    <app-left-nav></app-left-nav>
  </div>
  <div class="app-content">
    <app-header></app-header>
    <div class="page-content">
      <ngx-spinner
        bdColor="rgba(255, 255, 255, 0.74)"
        size="medium"
        color="#003f2d"
        type="square-jelly-box"
        [fullScreen]="true"
        template="<div class='container-load-custom'>
          <div class='loader-container-custom'>
            <div class='block-custom'></div>
            <div class='block-custom'></div>
            <div class='block-custom'></div>
            <div class='block-custom'></div>
          </div>
          <div class='loader-text-custom'>Loading...</div>
        </div>"
      >
      </ngx-spinner>
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</div>
