<div class="app-header">
  <div class="header-menu">
    <!-- <div class="header-item">
      <span class="material-icons md-48">settings</span>
    </div>
    <div class="header-item">
      <div class="notifications">
        <span class="material-icons md-48">notifications</span>
        <span class="counter">
          <span class="count">4</span>
        </span>
      </div>
    </div> -->
    <div class="header-item">
      <div class="user-avatar" style=" cursor: default;">
        <span>{{getUserHint() | uppercase }}</span>
      </div>
    </div>
  </div>
</div>